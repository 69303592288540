import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { AuthProvider, type AuthProviderProps } from 'react-oidc-context';
import { WebStorageStateStore } from 'oidc-client-ts';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';

const oidcConfig: AuthProviderProps = {
  authority: import.meta.env.VITE_PF_FEDHUB_URL,
  client_id: import.meta.env.VITE_PF_CLIENT_ID,
  redirect_uri: import.meta.env.VITE_PF_REDIRECT_URL,
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  scope: 'openid profile email',
  client_secret: import.meta.env.VITE_PF_CLIENT_SECRET,
  loadUserInfo: true,
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <AuthProvider {...oidcConfig}>
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </QueryClientProvider>
    </React.StrictMode>
  </AuthProvider>,
);
